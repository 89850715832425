#login-container {
    background-color: #272d43;
    background-image: url("../images/kiosk_bg.png");
    height: 100%;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    min-height: 100vh;
    justify-content: center;
}
#login-card {
    text-align: center;
    width: calc(100% - 30px);
    max-width: 400px;
    border-radius: 10px;
    background-color: #f2f2f2;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    color: #333;
}
#login-card form {
    font-size: 18px;
}
#login-card label {
    text-align: left;
    display: block;
    font-weight: 600;
    font-size: 18px;
}
#login-card input {
    width: 100%;
    margin-bottom: 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
#login-card input:focus {
    border-color: #ced4da;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.24);
}
#login-card button {
    width: 100%;
    color: #fff;
    font-weight: 700;
    max-width: 300px;
    background-color: #D41C18;
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
#login-card button:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
#login-card .card-footer {
    background: transparent;
    min-height: 74px;
}
#login-card .card-footer svg{
    width: 100px;
}
#login-card .card-header {
    font-weight: 700;
    background: transparent;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
}
