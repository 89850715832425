#questions-fields-list {
    margin: 0;
    padding: 0;
    i {
        pointer-events: none;
    }
    .toggle-question {
        width: 50px;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid #CED4DA;
        border-radius: 3px;
        margin-right: 20px;
        cursor: pointer;
    }
    .drag-wrapper.drag-wrapper-connected-article,
    .drag-wrapper.drag-wrapper-question-options {
        width: 50px;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid #CED4DA;
        border-radius: 3px;
        margin-right: 20px;
        cursor: pointer;
    }
    .remove-el {
        width: 50px;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid #CED4DA;
        border-radius: 3px;
        margin-left: 20px;
        cursor: pointer;
    }
    & > .collection-fields-group {
        border: 1px solid rgba(0,0,0,0.125);
        border-radius: .25em;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        padding: 1.25em;

        .toggle-content {
            display: none;
        }
        &.open {
            .toggle-question {
                i {
                    transform: rotate(180deg);
                }
            }
            .toggle-content {
                display: block;

            }
        }
        [id^="poll_questions_"] {
            flex-grow: 2;
            .questions-row {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                margin-bottom: 30px;
                .form-group {
                    max-width: calc(50% - 15px);
                    width: 100%;
                    margin: 0;
                    &--type {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        label {
                            margin: 0 15px 0 0;
                            width: auto;
                        }
                        select {
                            margin: 0;
                            width: auto;
                        }
                    }
                }
            }
        }

    }
    [id^="question-options-"] {
        margin: 0;
        padding: 0;
        & > .collection-fields-group {
            margin: 0 0 30px;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            & > .form-group {
                flex-grow: 2;
            }
        }
    }
}
