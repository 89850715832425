.input-group-addon {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 25px;
    &--top {
        align-items: flex-start;
        align-content: flex-start;
        padding-top: 11px;
    }
}
