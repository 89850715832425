#contents-fields-list {
    .collection-fields-group {
        margin-bottom: 30px;
    }
}

.connection-list, .connection-parent {
    &__connection {
        &-item {
            flex-grow: 2;
            padding-right: 15px;
            position: relative;
            &-id {
                padding-right: 15px;
            }
            label, input {
                width: 100%;
            }
            input {
                border: 1px solid #ced4da;
                border-radius: .25rem;
                height: calc(1.5em + .75rem + 2px);
                padding: 0 10px;
                &:focus {
                    outline: none;
                }
            }
            &.finding {
                input {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .connection-list__result,
                .connection-parent__result {
                    border-radius: .25rem;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    background-image: url("http://nowy.wybieramydom.pl/admin/images/svg/loader.svg");
                    background-size: 50px 50px;
                    background-position: center;
                    background-repeat: no-repeat;
                    min-height: 100px;
                    border: 1px solid #ced4da;
                    border-top: none;
                }
            }
            &.finded {
                input {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .connection-list__result,
                .connection-parent__result {
                    border: 1px solid #ced4da;
                    border-radius: .25rem;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 7px 0;
                    border-top: none;
                }
            }
        }
    }
    &__result {
        background-color: #ffffff;
        &-item {
            cursor: pointer;
            padding: 0 10px;
            &:hover {
                color: #ffffff;
                background-color: #007bff;
            }
            &--text {
                cursor: default;
                padding: 0 10px;
                font-weight: 700;
            }
        }
        span {
            display: block;
            padding: 0 10px;
        }
    }

}
.connection-parent__connection {
    margin-bottom: 1rem;
    .connection-parent__connection-item {
        padding: 0;
    }
    .connection-parent__connection-item-id {
        padding-right: 0;
        margin-bottom: 10px;
    }
}
.connection-parent__clear {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 37px;
    height: 36px;
    background-color: #fff;
    cursor: pointer;
    &:after, &:before {
        content: "";
        width: 50%;
        height: 2px;
        border-radius: 2px;
        background-color: #1a1b1c;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform-origin: 50% 50%;
        transform: rotate(45deg);
        border-radius: .25rem;
    }
    &:after {
        transform: rotate(-45deg);
    }
}
