#editor {
    width: 100%;
    min-height: 600px;
    height: 100%;
}
.nav.nav-tabs {
    padding: 5px 10px 0;
}
.tox-toolbar__primary > div:first-of-type .tox-icon.tox-tbtn__icon-wrap {
    position: relative;
}
.tox-toolbar__primary > div:first-of-type .tox-icon.tox-tbtn__icon-wrap::before {
    content: '';
    background-image: url("https://avt.pl/img/logo.png");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background-size: contain;
}
#content_introText_value {
    max-height: 150px;
}
.form-group {
    label {
        width: 100%;
    }
}
.select2 {
    width: 100%;
}
#product-fields-list, #contents-fields-list {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
    .collection-fields-group {
        display: flex;
        width: 100%;
        & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            cursor: pointer;
            &.remove-el {
                color: red;
            }
        }
        & > div {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            .form-group {
                max-width: 30%;
                flex: 0 0 30%;
            }
        }
    }
    .drag-wrapper, .dropdown-el {
        border: 1px solid #CED4DA;
        margin-right: 20px;
        max-width: 50px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        cursor: pointer;
        margin-top: 13px;
        i {
            font-size: 20px;
        }
    }
    .remove-el {
        max-width: 50px;
        width: 100%;
        height: 50px;
        background-color: #DC3545;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 3px;
        margin-left: 46px;
        margin-right: 20px;
        cursor: pointer;
        margin-top: 13px;
        i {
            font-size: 20px;
            &::before {
                color: #fff;
            }
        }
    }
}
#contents-fields-list {
    .select2 {
        width: 100% !important;
    }
    .collection-fields-group {
        .form-group {
            width: 100%;
            max-width: 100% !important;
            flex: 0 0 100% !important;
        }
    }
}

#firm-fields-list {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
    & > li {
        transition: .3s all;
        margin-bottom: 25px;
    }
}

#firm-fields-list {
    li {
        & > div {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
        }
    }
}

.save-options {
    margin: 0px -15px 30px -15px;
    padding: 15px;
    box-shadow: 0px 11px 28px -10px rgba(0,0,0,0.75);
}

#firm-fields-list {
    .collection-fields-group {
        .drag-wrapper {
            margin-left: 20px;
        }
        &-top {
            display: flex;
            .row {
                max-width: calc(100% - 256px);
                width: calc(100% - 256px);
            }
        }
        &-bottom {
            overflow: hidden;
            max-height: 0px;
            & > .row {
                margin-left: 75px;
                width: calc(100% - 256px);
            }
            &.show {
                max-height: 1000px;
            }
        }
        &-map {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: #5D708D;
            color: #fff;
            padding: 15px;
            &-title {
                font-size: 27px;
                line-height: 34px;
                font-weight: 700;
                margin-right: 59px;
                margin-left: 55px;
            }
            &-fields {
                margin-right: 30px;
            }
        }
        .drag-wrapper, .dropdown-el {
            border: 1px solid #CED4DA;
            margin-right: 20px;
            max-width: 50px;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            cursor: pointer;
            margin-top: 13px;
            i {
                font-size: 20px;
                pointer-events: none;
            }
        }
    }
    .remove-el {
        max-width: 50px;
        width: 100%;
        height: 50px;
        background-color: #DC3545;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 3px;
        margin-left: 46px;
        margin-right: 20px;
        cursor: pointer;
        margin-top: 13px;
        i {
            font-size: 20px;
        }
    }
}

#map {
    height: 512px;
    max-width: 512px;
    width: 100%;
}

#customfields-fields-list {
        list-style: none;
        padding: 0;
}

form[name="custom_field"] {
    #custom_field_fieldValueType {
        option {
            color: green;
        }
        option[disabled] {
            color: red;
        }
    }
}


#custom-tabs-customfields {
    .customfields {
        &__button {
            &.cf-active {
                i.fa-angle-down {
                    transform: rotate(180deg);
                }
            }
            .btn {
                text-decoration: none;
                &:focus {
                    box-shadow: none;
                    text-decoration: none;
                }
            }
        }
    }
}